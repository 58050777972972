iframe {
  position: relative;
  top: -30%;
  z-index: 2;
}

.top-border,
.bottom-border {
  position: relative;
  display: flex;
  width: 100%;
  height: 30%;
  z-index: 1;
}
.top-border {
  top: -1px;
  left: -1px;
}
.bottom-border {
  bottom: calc(60% - 1px);
  right: -1px;
  justify-content: flex-end;
}

.top-border:before,
.bottom-border:after {
  content: "";
  width: 35%;
  height: 100%;
}

.top-border:after,
.top-border:before {
  border-top: 1px solid currentColor;
}

.bottom-border:before,
.bottom-border:after {
  border-bottom: 1px solid currentColor;
}

.top-border:before,
.bottom-border:before {
  border-left: 1px solid currentColor;
}
.top-border:after,
.bottom-border:after {
  border-right: 1px solid currentColor;
}
