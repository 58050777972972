@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Morganite;
    font-weight: 400;
    src: url("./assets/fonts/Morganite/Morganite-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: Morganite;
    font-style: italic;
    src: url("./assets/fonts/Morganite/Morganite-BlackItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: Morganite;
    font-weight: bold;
    src: url("./assets/fonts/Morganite/Morganite-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: Morganite;
    font-weight: bold;
    font-style: italic;
    src: url("./assets/fonts/Morganite/Morganite-BoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: Geometric415BlkBt;
    font-weight: 400;
    src: url("./assets/fonts/Geometric-415-Black-Bt.ttf") format("truetype");
  }
  @font-face {
    font-family: DirtyOldTown;
    src: url("./assets/fonts/dirtyoldtown.ttf") format("truetype");
  }
  @font-face {
    font-family: GameOfSquids;
    src: url("./assets/fonts/Game Of Squids.ttf") format("truetype");
  }
  @font-face {
    font-family: GreatSejagad;
    src: url("./assets/fonts/Great Sejagad.ttf") format("truetype");
  }
  @font-face {
    font-family: HeyNovember;
    src: url("./assets/fonts/Hey November.ttf") format("truetype");
  }
  @font-face {
    font-family: Metropolitan;
    src: url("./assets/fonts/Metropolitan.ttf") format("truetype");
  }
  @font-face {
    font-family: StarJedi;
    src: url("./assets/fonts/Starjedi.ttf") format("truetype");
  }
}

:root {
  overscroll-behavior: none;
  background-color: black;
  height: 100%;
}

#root {
  overscroll-behavior: none;
  height: 100%;
}

body {
  height: 100%;
}

html {
  height: 100%;
}
